import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react';

export class Recordings extends Component {
    static displayName = Recordings.name;

    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
        this.state = { notes: [], loading: true, selectedNote: null };
        this.loadAudio = this.loadAudio.bind(this);
        this.play = this.play.bind(this);
    }

    componentDidMount() {
        this.getRecordings();
    }

    loadAudio(note) {
        this.setState({ selectedNote: note });
        console.log(this.audioRef);
        setTimeout(this.play, 0);
    }

    play() {
        this.audioRef.current.play();
    }

    render() {
        return (
            <div>
                <h1 id="tabelLabel" >Записи разговоров</h1>
                {this.state.loading
                    ? <p><em>Loading...</em></p>
                    : <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Имя</th>
                                <th>Телефон</th>
                                <th>Дата звонка</th>
                                <th>Аудио</th>
                                <th>Текст</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.notes.map(note =>
                                <tr key={note.id} className={this.state.selectedNote?.id == note.id ? 'table-primary' : undefined}>
                                    <td>{note.callerName}</td>
                                    <td>{note.callerPhone}</td>
                                    <td>{note.callDate}</td>
                                    <td>
                                        <button className="btn btn-primary btn-sm" onClick={() => { this.loadAudio(note) }}>
                                            <FontAwesomeIcon icon={faPlay} /> Воспроизвести
                                        </button>
                                    </td>
                                    <td>{note.text === null ? 'Не распознан' : note.text}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
                <div className="position-sticky bottom-0 d-flex">
                    {this.state.selectedNote
                        ? <>
                            <audio
                                className="bg-secondary ms-auto rounded-top"
                                ref={this.audioRef}
                                controls
                                src={`/api/Recordings/GetRecording?id=${this.state.selectedNote.id}`} />
                        </>
                        : null}
                </div>
            </div>
        );
    }

    async getRecordings() {
        const response = await fetch('api/Recordings/GetRecordingList');
        const data = await response.json();
        this.setState({ notes: data, loading: false });
    }
}
