import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import UserStore from './stores/userStore';
import { Provider } from 'mobx-react';
import Consts from './helpers/consts'

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.userStore = new UserStore();
    }

    async componentDidMount() {
        let isLogged = await this.userStore.getCurrentUser();
        if (!isLogged) {
            if (window.location.pathname !== "/") {
                window.location.href = `${Consts.loginUrl}?returnUrl=${encodeURIComponent(document.URL)}`
            }
        }
    }

    render() {
        return (
            <Provider userStore={this.userStore}>
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            </Provider>
        );
    }
}
