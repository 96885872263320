import { Recordings } from "./components/Recordings";
import { Notes } from "./components/Notes";
import { Home } from "./components/Home";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/notes',
        element: <Notes />
    },
    {
        path: '/recordings',
        element: <Recordings />
    }
];

export default AppRoutes;
