import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from '../helpers/withRouter.js'
import './NavMenu.css';
import api from '../api.js';
import { inject, observer } from "mobx-react"
import Consts from '../helpers/consts'

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.getLoginUrl = (currentUrl) => { return `${Consts.loginUrl}?returnUrl=${encodeURIComponent(currentUrl)}` }
        this.userStore = this.props.userStore;

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    async handleLogout() {
        if (window.confirm("Подтвердите выход")) {
            let response = await api.users.logout();
            if (response.ok) {
                this.userStore.changeUser(null);
                this.props.history.push(`/`);
            }
        }
    }

    render() {
        let isActive = (...routes) => {
            return routes.some(m => this.props.router.location.pathname.startsWith(m)) ? true : undefined;
        };

        return (
            <header className="nav-header position-sticky top-0 bg-white">
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/">Записная книжка</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            {this.userStore.userName
                                ? <>
                                    <NavItem>
                                        <NavLink tag={Link} to="/notes" active={isActive("/notes")}>Заметки</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/recordings" active={isActive("/recordings")}>Записи разговоров</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={this.handleLogout} style={{ cursor: "pointer" }} >Выйти</NavLink>
                                    </NavItem>
                                </>
                                : <NavItem>
                                    <a href={this.getLoginUrl(document.URL)} className="nav-link-btn">Войти</a>
                                </NavItem>
                            }
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}

export default inject('userStore')(withRouter(observer(NavMenu)));