import { Modal, ModalBody } from 'reactstrap';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileZipper, faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import './Notes.css';
import api from '../api'

export class Notes extends Component {
    static displayName = Notes.name;

    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            loading: true,
            show: false,
            id: 0,
            title: "",
            text: "",
        };
        this.hide = this.hide.bind(this);
        this.show = this.onAddClick.bind(this);
        this.createNote = this.createNote.bind(this);
        this.onTitleChange = this.onTitleChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    async componentDidMount() {
        await this.getNotes();
    }

    hide() {
        this.setState({ show: false });
        document.body.style.overflow = "visible";
    }

    onAddClick() {
        this.setState({
            show: true,
            id: 0,
            title: "",
            text: ""
        });
    }

    async createNote() {
        this.setState({
            show: false,
            title: "",
            text: "",
        });
        let response = await api.notes.createNote(this.state.title, this.state.text);
        await this.getNotes();
    }

    onTitleChange(e) {
        this.setState({ title: e.target.value });
    }

    onTextChange(e) {
        this.setState({ text: e.target.value });
    }

    onEditClick(note) {
        this.setState({
            show: true,
            id: note.id,
            title: note.title,
            text: note.text,
        });
    }

    async onDeleteClick(note) {
        if (window.confirm("Удалить заметку?")) {
            let response = await api.notes.deleteNote(note.id);
            await this.getNotes();
        }
    }

    render() {
        return (
            <div>
                <h1 id="tabelLabel" >Заметки</h1>
                <button onClick={() => { this.onAddClick(); }} className="btn btn-primary">Добавить</button>
                <div className="mt-3">
                    {this.state.loading
                        ? <p><em>Loading...</em></p>
                        : <div className="d-flex flex-wrap gap-3">
                            {this.state.notes.map(note =>
                                <div key={note.id} className="note-card card flex-grow-1" style={{ width: "18rem" }}>
                                    <div className="card-body d-flex flex-column">
                                        {note.title ? <h5 className="card-title">{note.title}</h5> : undefined}
                                        <p className="card-text">{note.text}</p>
                                        <div className="d-flex flex-row-reverse gap-3 mt-auto">
                                            <a className="note-card-link link-secondary" title="Удалить" onClick={() => { this.onDeleteClick(note); }}><FontAwesomeIcon icon={faTrashCan} size="lg" /></a >
                                            <a className="note-card-link link-secondary" title="Отправить в архив"><FontAwesomeIcon icon={faFileZipper} size="lg" /></a >
                                            <a className="note-card-link link-secondary" title="Изменить" onClick={() => { this.onEditClick(note); }}><FontAwesomeIcon icon={faPenToSquare} size="lg" /></a >
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
                <Modal
                    isOpen={this.state.show}
                    toggle={this.hide}
                    modalTransition={{ timeout: 200 }}
                    className="modal-dialog-centered">
                    <ModalBody>
                        <div className="mb-3">
                            <input onChange={this.onTitleChange} value={this.state.title} type="text" className="form-control" placeholder="Заголовок" />
                        </div>
                        <div className="mb-3">
                            <textarea onChange={this.onTextChange} value={this.state.text} className="form-control" rows="8" placeholder="Текст заметки..."></textarea>
                        </div>
                        <div className="d-flex">
                            {this.state.id === 0 ? < button onClick={() => { this.createNote(); }} className="btn btn-primary ms-auto">Добавить</button> : undefined}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    async getNotes() {
        const response = await fetch('api/Notes/GetNotesList');
        const data = await response.json();
        this.setState({
            notes: data,
            loading: false
        });
    }
}
