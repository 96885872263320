const api = {
    users: {
        isSignedIn: async () => {
            let response = await fetch(`api/user/isSignedIn`);
            return response;
        },
        logout: async () => {
            let response = await fetch(`api/user/logout`, { method: "post" });
            return response;
        },
    },
    notes: {
        getNoteList: async () => {
            let response = await fetch(`api/notes/getNoteList`);
            return response;
        },
        createNote: async (title, text) => {
            let response = await fetch("api/notes/createNote", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: title,
                    text: text
                }),
            });
            return response;
        },
        deleteNote: async (id) => {
            let response = await fetch(`api/notes/deleteNote?id=${id}`, { method: 'delete' });
            return response;
        },
    },
    recordings: {
        getRecordingList: async () => {
            let response = await fetch(`api/recordings/getRecordingList`);
            return response;
        },
        getRecording: async (id) => {
            let response = await fetch(`api/recordings/getRecording?id=${id}`);
            return response;
        },
    },
}
export default api;